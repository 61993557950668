import React, { useEffect, useState } from "react";
import firebase from "../../services/firebase";
import { makeStyles, CircularProgress } from "@material-ui/core";

export const AuthContext = React.createContext();

const useStyles = makeStyles((theme) => ({
  logged: {
    display: "flex",
  },
  loader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const AuthProvider = ({ children }) => {
  const classes = useStyles();

  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
