import React, { useEffect, useState, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";

import { Link as RouterLink } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import SignOutButton from "../Users/sign-out";
import HomeIcon from "@material-ui/icons/HomeRounded";

import firebase from "../../services/firebase";
import { AuthContext } from "../App/auth";
import { Divider } from "@material-ui/core";
import WorkIcon from "@material-ui/icons/Work";
import GroupIcon from "@material-ui/icons/Group";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    width: "175px",
  },
}));

function Navigation() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user, setUser] = useState(null);

  const theme = useTheme();
  const classes = useStyles();

  const { currentUser } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser) {
      const listener = firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .onSnapshot(function (doc) {
          const data = doc.data();
          data.uid = doc.id;
          setUser(data);
        });
      return function cleanup() {
        listener();
      };
    }
  }, [currentUser]);

  if (
    window.location.pathname === ROUTES.SIGN_IN ||
    window.location.pathname === ROUTES.PASSWORD_FORGET
  ) {
    return <></>;
  }

  if (!currentUser) {
    return <></>;
  }

  const drawer = (
    <>
      <List>
        <ListItem
          button
          key="home"
          component={RouterLink}
          to={ROUTES.DASHBOARD}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={t("Home")} />
        </ListItem>
      </List>
      <Divider />
      <List>
        {user && user.role === "admin" && (
          <ListItem
            button
            key="clients"
            component={RouterLink}
            to={ROUTES.CLIENTS}
          >
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText primary={t("Clients")} />
          </ListItem>
        )}
        {user && user.role === "admin" && (
          <ListItem
            button
            key="user-list"
            component={RouterLink}
            to={ROUTES.USERS}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={t("Admin Users")} />
          </ListItem>
        )}
      </List>
    </>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <img
            src="assets/logo.png"
            className={classes.logo}
            alt="Legal Concept"
          />
          <div className={classes.title}></div>
          <SignOutButton firebase={firebase} />
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          // container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar} />
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
}

export default Navigation;
