import React, { useState, useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DisplayUploadsComponent from "./display-uploads";

import firebase from "../../services/firebase";
import {
  Link,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  DialogContentText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Header from "../../utils/header";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loading: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dataFields: {
    marginBottom: 18,
  },
  clientDetails: {
    marginBottom: 30,
  },
}));

function ClientDetailsPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [clientName, setClientName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [type, setType] = useState("");
  const [files, setFiles] = useState({
    passportFiles: {},
    proofOfResidenceFiles: {},
    professionalCertificationFiles: {},
    proofFiles: {},
    legalRepresentativeFiles: {},
    contactPersonFiles: {},
  });

  const [loadingClient, setLoadingClient] = useState(false);

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userEmailTaken, setUserEmailTaken] = useState(false);
  const [userEmailError, setUserEmailError] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [userToEdit, setUserToEdit] = useState(null);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  const clientId = props.match.params.id;

  const { t } = useTranslation();

  const isInvalidUser =
    userFirstName === "" ||
    userLastName === "" ||
    userEmail === "" ||
    userEmailTaken === true;

  const isInvalidEditUser = userFirstName === "" || userLastName === "";

  useEffect(() => {
    setLoadingClient(true);
    const clientListener = firebase
      .firestore()
      .collection("clients")
      .doc(clientId)
      .onSnapshot(
        function (doc) {
          if (doc.exists) {
            const data = doc.data();

            setClientName(data.name);
            setEmail(data.email);
            setPhoneNumber(data.phoneNumber);
            setType(data.type);

            setLoadingClient(false);
          } else {
            // doc.data() will be undefined in this case
            setError("No such document!");
          }
        },
        function (error) {
          setError(error);
        }
      );

    let cFiles = {};

    firebase
      .storage()
      .ref(`uploads/clients/${clientId}`)
      .listAll()
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          let folderName = folderRef.name.replace(/-([a-z])/g, (g) => {
            return g[1].toUpperCase();
          });

          cFiles[folderName] = {};

          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
          folderRef.listAll().then((resFolder) => {
            resFolder.items.forEach((resItem) => {
              if (cFiles[folderName] === undefined) {
                cFiles[folderName] = {};
              }

              cFiles[folderName][resItem.name] = {};

              resItem.getDownloadURL().then((url) => {
                if (cFiles[folderName] === undefined) {
                  cFiles[folderName] = {};
                }

                if (cFiles[folderName][resItem.name] === undefined) {
                  cFiles[folderName][resItem.name] = {};
                }

                cFiles[folderName][resItem.name]["url"] = url;
                cFiles[folderName][resItem.name]["uploaded"] = true;
                setFiles({ ...files, ...cFiles });
              });

              resItem.getMetadata().then((metadata) => {
                if (cFiles[folderName] === undefined) {
                  cFiles[folderName] = {};
                }

                if (cFiles[folderName][resItem.name] === undefined) {
                  cFiles[folderName][resItem.name] = {};
                }

                cFiles[folderName][resItem.name]["contentType"] =
                  metadata.contentType;
                cFiles[folderName][resItem.name]["name"] = metadata.name;
                setFiles({ ...files, ...cFiles });
              });
            });
          });
        });
      });

    return function cleanup() {
      clientListener();
    };
  }, [clientId]);

  useEffect(() => {
    setLoadingUsers(true);

    const listener = firebase
      .firestore()
      .collection("users")
      .where("clientId", "==", clientId)
      .onSnapshot(function (querySnapshot) {
        const userObjects = [];
        querySnapshot.forEach(function (doc) {
          const userObject = doc.data();
          userObject.uid = doc.id;

          userObjects.push(userObject);
        });

        setUsers(userObjects);
        setLoadingUsers(false);
      });

    return function cleanup() {
      listener();
    };
  }, [clientId]);

  const submitUserForm = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await firebase
          .firestore()
          .collection("users")
          .where("email", "==", userEmail)
          .get()
          .then(async function (querySnapshot) {
            if (querySnapshot.size) {
              setUserEmailTaken(true);
              setUserEmailError(t("This email has been taken"));
            } else {
              await firebase.firestore().collection("newUsers").add({
                email: userEmail,
                firstName: userFirstName,
                lastName: userLastName,
                phoneNumber: userPhoneNumber,
                password: userPassword,
                clientId: clientId,
              });

              closeCreateUserDialogHandler();
            }
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      } catch (error) {
        setError(error);
      }
    },
    [
      userFirstName,
      userLastName,
      userEmail,
      userPassword,
      userPhoneNumber,
      t,
      clientId,
    ]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openCreateUserDialogHandler = () => {
    setCreateUserDialogOpen(true);
  };

  const closeCreateUserDialogHandler = () => {
    setCreateUserDialogOpen(false);
    setUserFirstName("");
    setUserLastName("");
    setEmail("");
    setUserPassword("");
    setPhoneNumber("");
  };

  const openEditUserDialogHandler = (userId) => {
    setLoadingUser(true);
    setUserToEdit(userId);
    setEditUserDialogOpen(true);

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const data = doc.data();

          setLoadingUser(false);
          setUserEmail(data.email);
          setUserFirstName(data.firstName);
          setUserLastName(data.lastName);
          setUserPhoneNumber(data.phoneNumber);
        }
      });
  };

  const closeEditUserDialogHandler = () => {
    setEditUserDialogOpen(false);
    setUserFirstName("");
    setUserLastName("");
    setUserPhoneNumber("");
    setUserEmail("");
    setUserPassword("");
    setUserToEdit(null);
  };

  const submitEditUserForm = useCallback(
    (event) => {
      event.preventDefault();
      try {
        firebase.firestore().collection("users").doc(userToEdit).update({
          firstName: userFirstName,
          lastName: userLastName,
          phoneNumber: userPhoneNumber,
        });

        closeEditUserDialogHandler();
      } catch (error) {
        setError(error);
      }
    },
    [userFirstName, userLastName, userPhoneNumber, userToEdit]
  );

  function deleteUser(userId) {
    firebase.firestore().collection("users").doc(userId).delete();
    setUserToDelete(null);
    setDialogOpen(false);
  }

  const openDeleteDialogHandler = (userId) => {
    setUserToDelete(userId);
    setDialogOpen(true);
  };

  const closeDeleteDialogHandler = () => {
    setUserToDelete(null);
    setDialogOpen(false);
  };

  if (loadingClient) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  const columns = [
    {
      id: "name",
      label: t("Name"),
      minWidth: 170,
      format: (row) => `${row.firstName} ${row.lastName}`,
    },
    { id: "email", label: t("Email Address"), minWidth: 100 },
    { id: "phoneNumber", label: t("Phone Number"), minWidth: 100 },
    {
      id: "edit",
      label: t("Edit"),
      minWidth: 100,
      format: (row) => (
        <Link href="#" onClick={() => openEditUserDialogHandler(row.uid)}>
          {t("Edit")}
        </Link>
      ),
    },
    {
      id: "delete",
      label: t("Delete"),
      minWidth: 100,
      format: (row) => (
        <Link href="#" onClick={() => openDeleteDialogHandler(row.uid)}>
          {t("Delete")}
        </Link>
      ),
    },
  ];

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Card className={classes.clientDetails}>
          <CardHeader title={clientName}></CardHeader>
          <CardContent>
            <Typography variant="overline" color="secondary">
              {t("Email Address")}
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              className={classes.dataFields}
            >
              {email}
            </Typography>
            <Typography variant="overline" color="secondary">
              {t("Phone Number")}
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              className={classes.dataFields}
            >
              {phoneNumber}
            </Typography>
            <Typography variant="overline" color="secondary">
              {t("Type")}
            </Typography>
            <Typography variant="body2" color="primary">
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </Typography>
          </CardContent>
        </Card>
      </Container>

      <Container>
        <Grid container spacing={2}>
          {type === "person" && (
            <Grid item xs={12} sm={4}>
              <DisplayUploadsComponent
                name={"passport"}
                label={t("Passport")}
                files={files.passportFiles}
              />
            </Grid>
          )}
          {type === "person" && (
            <Grid item xs={12} sm={4}>
              <DisplayUploadsComponent
                name={"proofOfResidence"}
                label={t("Proof Of Residence")}
                files={files.proofOfResidenceFiles}
              />
            </Grid>
          )}
          {type === "person" && (
            <Grid item xs={12} sm={4}>
              <DisplayUploadsComponent
                name={"professionalCertification"}
                label={t("Professional Certification")}
                files={files.professionalCertificationFiles}
              />
            </Grid>
          )}
          {type === "company" && (
            <Grid item xs={12} sm={4}>
              <DisplayUploadsComponent
                name={"proof-of-registration"}
                label={t("Proof of registration")}
                files={files.proofFiles}
              />
            </Grid>
          )}
          {type === "company" && (
            <Grid item xs={12} sm={4}>
              <DisplayUploadsComponent
                name={"legal-representative-id"}
                label={t("Legal Representative ID")}
                files={files.legalRepresentativeFiles}
              />
            </Grid>
          )}
          {type === "company" && (
            <Grid item xs={12} sm={4}>
              <DisplayUploadsComponent
                name={"contact-passport"}
                label={t("Contact Passport")}
                files={files.contactPersonFiles}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      <Container component="main">
        <Header title={t("Registered users for this client")}>
          <Button onClick={openCreateUserDialogHandler}>{t("New User")}</Button>
        </Header>
        {loadingUsers && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
        {!loadingUsers && (
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.uid}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format ? column.format(row) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        )}
        <Dialog
          open={dialogOpen}
          onClose={closeDeleteDialogHandler}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{t("Delete User")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("Are you sure that you want to delete this user?")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteDialogHandler} color="primary">
              {t("Cancel")}
            </Button>
            <Button onClick={() => deleteUser(userToDelete)} color="secondary">
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={createUserDialogOpen}
          onClose={closeCreateUserDialogHandler}
          aria-labelledby="form-dialog-title"
          fullScreen={fullScreen}
        >
          <DialogTitle id="form-dialog-title">{t("Create User")}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label={t("First Name")}
                  autoFocus
                  onChange={(e) => setUserFirstName(e.target.value)}
                  value={userFirstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label={t("Last Name")}
                  name="lastName"
                  onChange={(e) => setUserLastName(e.target.value)}
                  value={userLastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={userEmailTaken === true}
                  required
                  fullWidth
                  id="email"
                  label={t("Email Address")}
                  name="email"
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                    setUserEmailError("");
                    setUserEmailTaken(false);
                  }}
                  value={userEmail}
                  helperText={userEmailError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label={t("Password")}
                  type="password"
                  id="password"
                  onChange={(e) => setUserPassword(e.target.value)}
                  value={userPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="phoneNumber"
                  label={t("Phone Number")}
                  id="phoneNumber"
                  type="tel"
                  onChange={(e) => setUserPhoneNumber(e.target.value)}
                  value={userPhoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                {error && <Alert severity="error">{error.message}</Alert>}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeCreateUserDialogHandler} color="primary">
              {t("Cancel")}
            </Button>
            <Button
              onClick={submitUserForm}
              color="secondary"
              disabled={isInvalidUser}
            >
              {t("Submit")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={editUserDialogOpen}
          onClose={closeEditUserDialogHandler}
          aria-labelledby="form-dialog-title"
          fullScreen={fullScreen}
        >
          <DialogTitle id="form-dialog-title">{t("Edit User")}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={loadingUser}
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label={t("First Name")}
                  autoFocus
                  onChange={(e) => setUserFirstName(e.target.value)}
                  value={userFirstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={loadingUser}
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label={t("Last Name")}
                  name="lastName"
                  onChange={(e) => setUserLastName(e.target.value)}
                  value={userLastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={loadingUser}
                  variant="outlined"
                  fullWidth
                  name="phoneNumber"
                  label={t("Phone Number")}
                  id="phoneNumber"
                  type="tel"
                  onChange={(e) => setUserPhoneNumber(e.target.value)}
                  value={userPhoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                {error && <Alert severity="error">{error.message}</Alert>}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditUserDialogHandler} color="primary">
              {t("Cancel")}
            </Button>
            <Button
              type="button"
              color="secondary"
              onClick={submitEditUserForm}
              disabled={isInvalidEditUser}
            >
              {t("Submit")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

export default withRouter(ClientDetailsPage);
