import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  listItem: {
    paddingRight: 80,
  },
}));

function DisplayUploadsComponent(props) {
  const classes = useStyles();

  const { t } = useTranslation();

  const [uploadsAsFile, setUploadsAsFile] = useState({});
  // const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    setUploadsAsFile(props.files);
  }, [props.files]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={props.label}
        titleTypographyProps={{
          variant: "overline",
          color: "secondary",
        }}
      />
      <CardContent className={classes.cardContent}>
        <List>
          {Object.keys(uploadsAsFile).map((key) => {
            let file = uploadsAsFile[key];
            return (
              <ListItem key={key} className={classes.listItem}>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{
                    variant: "caption",
                    noWrap: true,
                  }}
                />
                <ListItemSecondaryAction>
                  <Button
                    size="small"
                    color="secondary"
                    href={file["url"]}
                    target="_blank"
                  >
                    {t("View")}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
}

export default DisplayUploadsComponent;
