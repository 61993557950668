import React, { useState, useEffect, Fragment } from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { LinearProgress, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fileInput: {
    display: "none",
  },
  uploadField: {
    flexGrow: 1,
    overflow: "hidden",
  },
  uploadContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(3, 0, 2),
  },
  fileNameContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

function UploadComponent(props) {
  const classes = useStyles();

  const { t } = useTranslation();

  const [uploadsAsFile, setUploadsAsFile] = useState({});
  // const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    setUploadsAsFile(props.files);
  }, [props.files]);

  const handleImageAsFile = (e) => {
    Object.keys(e.target.files).map((key) => {
      uploadsAsFile[e.target.files[key]["name"]] = {
        file: e.target.files[key],
        name: e.target.files[key]["name"],
        progress: null,
        uploaded: false,
        action: "add",
      };

      return true;
    });

    setUploadsAsFile({ ...uploadsAsFile });

    props.onChange(uploadsAsFile);
  };

  const remove = (key) => {
    if (uploadsAsFile[key].uploaded === true) {
      uploadsAsFile[key].action = "remove";
    }

    if (uploadsAsFile[key].uploaded === false) {
      delete uploadsAsFile[key];
    }

    setUploadsAsFile({ ...uploadsAsFile });

    props.onChange(uploadsAsFile);
  };

  return (
    <>
      <Grid item xs={12} className={classes.uploadContainer}>
        <div className={classes.uploadField}>
          <Typography>{props.label}</Typography>
        </div>
        <input
          className={classes.fileInput}
          id={props.name}
          multiple
          type="file"
          onChange={handleImageAsFile}
        />
        <label htmlFor={props.name}>
          <Button variant="contained" color="secondary" component="span">
            {t("Upload")}
          </Button>
        </label>
      </Grid>
      {Object.keys(uploadsAsFile).map((key) => {
        let file = uploadsAsFile[key];
        if (file.uploaded === true && file.action !== "remove") {
          return (
            <Grid item key={key} xs={12} className={classes.fileNameContainer}>
              <div className={classes.uploadField}>
                <Typography>
                  <i>
                    <small>{file.name}</small>
                  </i>
                </Typography>
              </div>
              <Button
                size="small"
                color="secondary"
                href={file["url"]}
                target="_blank"
              >
                {t("View")}
              </Button>
              <IconButton
                onClick={() => remove(key)}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          );
        }

        if (file.uploaded === false) {
          return (
            <React.Fragment key={key}>
              <Grid
                item
                key={key}
                xs={12}
                className={classes.fileNameContainer}
              >
                <Typography className={classes.uploadField}>
                  <i>
                    <small>{`File to upload: ${file.file.name}`}</small>
                  </i>
                </Typography>
                <IconButton
                  onClick={() => remove(key)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              {file.progress !== null && (
                <Grid item key={`progress-${key}`} xs={12}>
                  <LinearProgress variant="determinate" value={file.progress} />
                </Grid>
              )}
            </React.Fragment>
          );
        }

        return <Fragment key={key}></Fragment>;
      })}
    </>
  );
}

export default UploadComponent;
