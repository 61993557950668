import React, { useState, useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";

import {
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import UploadComponent from "./upload-component";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fileInput: {
    display: "none",
  },
  uploadField: {
    flexGrow: 1,
  },
  uploadContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(3, 0, 2),
  },
}));

function ClientForm(props) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [type, setType] = useState("");
  const [passportFiles, setPassportFiles] = useState({});
  const [proofOfResidenceFiles, setProofOfResidenceFiles] = useState({});
  const [
    professionalCertificationFiles,
    setProfessionalCertificationFiles,
  ] = useState({});

  const [proofFiles, setProofFiles] = useState({});
  const [legalRepresentativeFiles, setLegalRepresentativeFiles] = useState({});
  const [contactPersonFiles, setContactPersonFiles] = useState({});
  const [error, setError] = useState("");

  const fileFunctionsMap = {
    proofFiles: setProofFiles,
    contactPersonFiles: setContactPersonFiles,
    legalRepresentativeFiles: setLegalRepresentativeFiles,
    passportFiles: setPassportFiles,
    professionalCertificationFiles: setProfessionalCertificationFiles,
    proofOfResidenceFiles: setProofOfResidenceFiles,
  };

  const fileValuesMap = {
    proofFiles,
    contactPersonFiles,
    legalRepresentativeFiles,
    passportFiles,
    professionalCertificationFiles,
    proofOfResidenceFiles,
  };

  const isInvalid =
    email === "" || name === "" || phoneNumber === "" || type === "";

  const { t } = useTranslation();

  useEffect(() => {
    setName(props.client.name);
    setEmail(props.client.email);
    setPhoneNumber(props.client.phoneNumber);
    setType(props.client.type);
    setPassportFiles(props.client.passportFiles);
    setProofOfResidenceFiles(props.client.proofOfResidenceFiles);
    setProfessionalCertificationFiles(
      props.client.professionalCertificationFiles
    );
    setProofFiles(props.client.proofFiles);
    setLegalRepresentativeFiles(props.client.legalRepresentativeFiles);
    setContactPersonFiles(props.client.contactPersonFiles);
  }, [props.client]);

  const submitForm = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const formData = {
          name: name,
          email: email,
          phoneNumber: phoneNumber,
          type: type,
        };

        formData.passportFiles = passportFiles;
        formData.proofOfResidenceFiles = proofOfResidenceFiles;
        formData.professionalCertificationFiles = professionalCertificationFiles;

        formData.proofFiles = proofFiles;
        formData.legalRepresentativeFiles = legalRepresentativeFiles;
        formData.contactPersonFiles = contactPersonFiles;

        props.onSubmit(formData);
      } catch (error) {
        setError(error);
      }
    },
    [
      email,
      name,
      phoneNumber,
      type,
      proofFiles,
      contactPersonFiles,
      legalRepresentativeFiles,
      passportFiles,
      professionalCertificationFiles,
      proofOfResidenceFiles,
      props,
    ]
  );

  const clearFiles = () => {
    [
      "passportFiles",
      "proofOfResidenceFiles",
      "professionalCertificationFiles",
      "proofFiles",
      "legalRepresentativeFiles",
      "contactPersonFiles",
    ].map((uploadName) => {
      if (Object.keys(fileValuesMap[uploadName]).length > 0) {
        Object.keys(fileValuesMap[uploadName]).map((key) => {
          if (fileValuesMap[uploadName][key].uploaded === true) {
            fileValuesMap[uploadName][key].action = "remove";
          }

          if (fileValuesMap[uploadName][key].uploaded === false) {
            delete fileValuesMap[uploadName][key];
          }

          fileFunctionsMap[uploadName](fileValuesMap[uploadName]);

          return true;
        });
      }

      return true;
    });
  };

  return (
    <form className={classes.form} noValidate onSubmit={submitForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="client"
            label={t("Client Name")}
            id="clientName"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            label={t("Email Address")}
            name="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="phoneNumber"
            label={t("Phone Number")}
            id="phoneNumber"
            type="tel"
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="client-type">{t("Type")} *</InputLabel>
            <Select
              labelId="client-type"
              id="client-type"
              value={type}
              label={t("Type")}
              onChange={(e) => {
                setType(e.target.value);
                clearFiles();
              }}
            >
              <MenuItem value="">
                <em>- {t("Select")} -</em>
              </MenuItem>
              <MenuItem value="person">{t("Person")}</MenuItem>
              <MenuItem value="company">{t("Company")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {type === "person" && (
          <UploadComponent
            name={"passport"}
            label={t("Passport")}
            onChange={setPassportFiles}
            files={passportFiles}
          />
        )}
        {type === "person" && (
          <UploadComponent
            name={"proofOfResidence"}
            label={t("Proof Of Residence")}
            onChange={setProofOfResidenceFiles}
            files={proofOfResidenceFiles}
          />
        )}
        {type === "person" && (
          <UploadComponent
            name={"professionalCertification"}
            label={t("Professional Certification")}
            onChange={setProfessionalCertificationFiles}
            files={professionalCertificationFiles}
          />
        )}
        {type === "company" && (
          <UploadComponent
            name={"proof-of-registration"}
            label={t("Proof of registration")}
            onChange={setProofFiles}
            files={proofFiles}
          />
        )}
        {type === "company" && (
          <UploadComponent
            name={"legal-representative-id"}
            label={t("Legal Representative ID")}
            onChange={setLegalRepresentativeFiles}
            files={legalRepresentativeFiles}
          />
        )}
        {type === "company" && (
          <UploadComponent
            name={"contact-passport"}
            label={t("Contact Passport")}
            onChange={setContactPersonFiles}
            files={contactPersonFiles}
          />
        )}
        <Grid item xs={12}>
          {error && <Alert severity="error">{error.message}</Alert>}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.buttonsContainer}>
        <Typography className={classes.uploadField}>
          <Link component={RouterLink} to={props.backUrl}>
            {t("Go Back")}
          </Link>
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isInvalid}
        >
          {props.submitLabel}
        </Button>
      </Grid>
    </form>
  );
}

export default withRouter(ClientForm);
