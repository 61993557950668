import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Copyright from "../App/copyright";
import firebase from "../../services/firebase";
import { useTranslation } from "react-i18next";

function PasswordForgetFormBase(props) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const { t } = useTranslation();

  function onSubmit(event) {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmail("");
        setError(null);
        setTimeout(() => {
          setShowSuccess(false);
        }, 5000);
        setShowSuccess(true);
      })
      .catch((error) => {
        setError(error);
      });

    event.preventDefault();
  }

  const isInvalid = email === "";

  return (
    <form className={props.classes.form} noValidate onSubmit={onSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={t("Email Address")}
        name="email"
        autoComplete="email"
        autoFocus
        onChange={(e) => setEmail(e.target.value)}
      />
      {error && <Alert severity="error">{error.message}</Alert>}
      {showSuccess && (
        <Alert severity="success">
          An email with instructions on how to reset your password has been sent
        </Alert>
      )}
      <Button
        disabled={isInvalid}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={props.classes.submit}
      >
        {t("Reset My Password")}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to={ROUTES.SIGN_IN} variant="body2">
            {t("Sign in")}
          </Link>
        </Grid>
        {/* <Grid item>
          <Link component={RouterLink} to={ROUTES.SIGN_UP} variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>
        </Grid> */}
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </form>
  );
}

const PasswordForgetForm = withRouter(PasswordForgetFormBase);

export { PasswordForgetForm };

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function PasswordForgetPage(props) {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("Password Reset")}
          </Typography>
          <PasswordForgetForm classes={classes} />
        </div>
      </Grid>
    </Grid>
  );
}

export default withRouter(PasswordForgetPage);
