import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import * as ROUTES from "../../constants/routes";
import { Link as RouterLink } from "react-router-dom";
import Header from "../../utils/header";

import { useTranslation } from "react-i18next";

import firebase from "../../services/firebase";

const useStyles = makeStyles((theme) => ({
  loading: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function ClientList(props) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);

    const listener = firebase
      .firestore()
      .collection("clients")
      .where("deleted", "==", 0)
      .onSnapshot(function (querySnapshot) {
        const clientObjects = [];
        querySnapshot.forEach(function (doc) {
          const clientObject = doc.data();
          clientObject.uid = doc.id;

          clientObjects.push(clientObject);
        });

        setClients(clientObjects);
        setLoading(false);
      });

    return function cleanup() {
      listener();
    };
  }, []);

  function deleteClient(clientId) {
    firebase.firestore().collection("clients").doc(clientId).update({
      deleted: 1,
    });

    setClientToDelete(null);
    setDialogOpen(false);
  }

  const columns = [
    {
      id: "name",
      label: t("Name"),
      minWidth: 170,
      format: (row) => (
        <Link component={RouterLink} to={`${ROUTES.CLIENT_DETAILS}/${row.uid}`}>
          {row.name}
        </Link>
      ),
    },
    { id: "email", label: t("Email Address"), minWidth: 100 },
    { id: "phoneNumber", label: t("Phone Number"), minWidth: 100 },
    {
      id: "type",
      label: t("Type"),
      minWidth: 100,
      format: (row) => t(row.type.charAt(0).toUpperCase() + row.type.slice(1)),
    },
    {
      id: "edit",
      label: t("Edit"),
      minWidth: 100,
      format: (row) => (
        <Link component={RouterLink} to={`${ROUTES.EDIT_CLIENT}/${row.uid}`}>
          {t("Edit")}
        </Link>
      ),
    },
    {
      id: "delete",
      label: t("Delete"),
      minWidth: 100,
      format: (row) => (
        <Link href="#" onClick={() => openDeleteDialogHandler(row.uid)}>
          {t("Delete")}
        </Link>
      ),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openDeleteDialogHandler = (clientId) => {
    setClientToDelete(clientId);
    setDialogOpen(true);
  };

  const closeDeleteDialogHandler = (clientId) => {
    setClientToDelete(null);
    setDialogOpen(false);
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Header title={t("Clients")}>
        <Button component={RouterLink} to={ROUTES.ADD_CLIENT}>
          {t("Add Client")}
        </Button>
      </Header>
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.uid}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={clients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={closeDeleteDialogHandler}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Are you sure that you want to delete this client?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialogHandler} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => deleteClient(clientToDelete)}
            color="secondary"
          >
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ClientList;
