import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Navigation from "../Navigation";
import SignInPage from "../Users/sign-in";
import PasswordForgetPage from "../Users/forgot-password";
import DashboardPage from "../Dashboard";

import * as ROUTES from "../../constants/routes";
import CssBaseline from "@material-ui/core/CssBaseline";

import { createMuiTheme, CircularProgress } from "@material-ui/core";

import { AuthProvider } from "./auth";
import PrivateRoute from "./private-route";
import Toolbar from "@material-ui/core/Toolbar";
import ClientList from "../Clients";
import AddClientPage from "../Clients/add";
import EditClientPage from "../Clients/edit";
import ClientDetailsPage from "../Clients/details";
import AdminUserList from "../Users/index";

import blueGrey from "@material-ui/core/colors/blueGrey";
import cyan from "@material-ui/core/colors/cyan";

import "../../services/i18n";

const useStyles = makeStyles((theme) => ({
  logged: {
    display: "flex",
  },
  loader: {
    position: "fixed",
    top: "45vh",
    left: "50vw",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  root: {
    width: "100%",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: cyan[400],
    },
  },
  overrides: {
    // Style sheet name
    MuiLink: {
      // Name of the rule
      root: {
        // Some CSS
        color: cyan[400],
      },
    },
  },
});

function App() {
  const classes = useStyles();

  return (
    <Suspense fallback={<CircularProgress className={classes.loader} />}>
      <AuthProvider>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />

              <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForgetPage}
              />

              <div className={classes.logged}>
                <Navigation />
                <div className={classes.root}>
                  <main className={classes.content}>
                    <Toolbar />
                    <PrivateRoute
                      exact
                      path={ROUTES.DASHBOARD}
                      component={DashboardPage}
                    />

                    <PrivateRoute
                      exact
                      path={`${ROUTES.EDIT_CLIENT}/:id`}
                      component={EditClientPage}
                    />
                    <PrivateRoute
                      exact
                      path={`${ROUTES.CLIENT_DETAILS}/:id`}
                      component={ClientDetailsPage}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTES.ADD_CLIENT}
                      component={AddClientPage}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTES.CLIENTS}
                      component={ClientList}
                    />

                    <PrivateRoute
                      exact
                      path={ROUTES.USERS}
                      component={AdminUserList}
                    />
                  </main>
                </div>
              </div>
            </Switch>
          </Router>
        </MuiThemeProvider>
      </AuthProvider>
    </Suspense>
  );
}

export default App;
