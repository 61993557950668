import React, { useState, useCallback, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import firebase from "../../services/firebase";
import { useTranslation } from "react-i18next";

import ClientForm from "./form";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fileInput: {
    display: "none",
  },
  uploadField: {
    flexGrow: 1,
  },
  uploadContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(3, 0, 2),
  },
}));

function EditClientPage(props) {
  const classes = useStyles();

  const [client, setClient] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    type: "",
    passportFiles: {},
    proofOfResidenceFiles: {},
    professionalCertificationFiles: {},
    proofFiles: {},
    legalRepresentativeFiles: {},
    contactPersonFiles: {},
    error: "",
  });

  const history = props.history;

  const clientId = props.match.params.id;

  let operations = useRef(0);

  const { t } = useTranslation();

  useEffect(() => {
    let clientData = {};
    firebase
      .firestore()
      .collection("clients")
      .doc(clientId)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const data = doc.data();

          clientData = {
            name: data.name,
            email: data.email,
            phoneNumber: data.phoneNumber,
            type: data.type,
          };

          setClient({ ...client, ...clientData });
        }
      });

    firebase
      .storage()
      .ref(`uploads/clients/${clientId}`)
      .listAll()
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          let folderName = folderRef.name.replace(/-([a-z])/g, (g) => {
            return g[1].toUpperCase();
          });

          clientData[folderName] = {};

          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
          folderRef.listAll().then((resFolder) => {
            resFolder.items.forEach((resItem) => {
              if (clientData[folderName] === undefined) {
                clientData[folderName] = {};
              }

              clientData[folderName][resItem.name] = {};

              resItem.getDownloadURL().then((url) => {
                if (clientData[folderName] === undefined) {
                  clientData[folderName] = {};
                }

                if (clientData[folderName][resItem.name] === undefined) {
                  clientData[folderName][resItem.name] = {};
                }

                clientData[folderName][resItem.name]["url"] = url;
                clientData[folderName][resItem.name]["uploaded"] = true;
                setClient({ ...client, ...clientData });
              });

              resItem.getMetadata().then((metadata) => {
                if (clientData[folderName] === undefined) {
                  clientData[folderName] = {};
                }

                if (clientData[folderName][resItem.name] === undefined) {
                  clientData[folderName][resItem.name] = {};
                }

                clientData[folderName][resItem.name]["contentType"] =
                  metadata.contentType;
                clientData[folderName][resItem.name]["name"] = metadata.name;
                setClient({ ...client, ...clientData });
              });
            });
          });
        });
      });
  }, [clientId]);

  const submitForm = useCallback((formData) => {
    try {
      const fileMap = {};

      const checkForRedirect = () => {
        if (operations.current === 0) {
          history.push(ROUTES.CLIENTS);
        }
      };

      const updateProgress = (snapShot) => {
        let fileData = fileMap[snapShot.ref.fullPath];
        let progress = (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
        formData[fileData.uploadName][fileData.key].progress = progress;

        if (progress === 100) {
          operations.current = operations.current - 1;
        }

        setClient({ ...formData });
      };

      const onError = (err) => {
        formData.error = err.message;

        setClient({ ...formData });
      };

      firebase.firestore().collection("clients").doc(clientId).update({
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        type: formData.type,
        deleted: 0,
      });

      let totalActions = 0;

      [
        "passportFiles",
        "proofOfResidenceFiles",
        "professionalCertificationFiles",
        "proofFiles",
        "legalRepresentativeFiles",
        "contactPersonFiles",
      ].map((uploadName) => {
        let keys = Object.keys(formData[uploadName]);
        // totalUploads += Object.keys(formData[uploadName]).length;
        if (keys.length > 0) {
          keys.map((key) => {
            if (formData[uploadName][key]["action"] !== undefined) {
              totalActions++;
            }

            return true;
          });
        }

        return true;
      });

      if (totalActions === 0) {
        history.push(ROUTES.CLIENTS);
      }

      [
        "passportFiles",
        "proofOfResidenceFiles",
        "professionalCertificationFiles",
        "proofFiles",
        "legalRepresentativeFiles",
        "contactPersonFiles",
      ].map((uploadName) => {
        let keys = Object.keys(formData[uploadName]);
        if (keys.length > 0) {
          let folderName = uploadName
            .replace(/([a-zA-Z])(?=[A-Z])/g, "$1-")
            .toLowerCase();

          keys.map((key) => {
            let file = formData[uploadName][key];

            let ref = null;

            switch (file.action) {
              case "remove":
                operations.current = operations.current + 1;

                ref = `uploads/clients/${clientId}/${folderName}/${file.name}`;

                firebase
                  .storage()
                  .ref(ref)
                  .delete()
                  .then(function () {
                    operations.current = operations.current - 1;
                    checkForRedirect();
                  })
                  .catch(function (error) {
                    // Uh-oh, an error occurred!
                  });
                break;
              case "add":
                operations.current = operations.current + 1;

                ref = `uploads/clients/${clientId}/${folderName}/${formData[uploadName][key].file.name}`;

                fileMap[ref] = {
                  key,
                  uploadName,
                };

                firebase
                  .storage()
                  .ref(ref)
                  .put(formData[uploadName][key].file)
                  .on(
                    "state_changed",
                    updateProgress,
                    onError,
                    checkForRedirect
                  );
                break;
              default:
                break;
            }

            return true;
          });
        }

        return true;
      });
      // console.log(formData);
    } catch (error) {
      formData.error = error.message;

      setClient({ ...formData });
    }
  }, []);

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {t("Edit Client")}
        </Typography>
        <ClientForm
          client={client}
          submitLabel={t("Save")}
          backUrl={ROUTES.CLIENTS}
          onSubmit={submitForm}
        ></ClientForm>
      </div>
    </Container>
  );
}

export default withRouter(EditClientPage);
