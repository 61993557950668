import React from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

function SignOutButton(props) {
  const { t } = useTranslation();

  return (
    <Button
      color="inherit"
      onClick={() => {
        props.firebase.auth().signOut();
      }}
    >
      {t("Sign Out")}
    </Button>
  );
}

export default SignOutButton;
