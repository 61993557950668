import React, { useState, useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import firebase from "../../services/firebase";
import {
  Link,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Header from "../../utils/header";

const useStyles = makeStyles((theme) => ({
  loading: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function AdminUserList(props) {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [error, setError] = useState("");

  const [users, setUsers] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userToDelete, setUserToDelete] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [emailError, setEmailError] = useState("");

  const [userToEdit, setUserToEdit] = useState(null);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  const clientId = props.match.params.id;

  const { t } = useTranslation();

  const columns = [
    {
      id: "name",
      label: "Name",
      minWidth: 170,
      format: (row) => `${row.firstName} ${row.lastName}`,
    },
    { id: "email", label: "Email Address", minWidth: 100 },
    { id: "phoneNumber", label: "Phone Number", minWidth: 100 },
    {
      id: "edit",
      label: "Edit",
      minWidth: 100,
      format: (row) => (
        <Link href="#" onClick={() => openEditUserDialogHandler(row.uid)}>
          {t("Edit")}
        </Link>
      ),
    },
    {
      id: "delete",
      label: "Delete",
      minWidth: 100,
      format: (row) => (
        <Link href="#" onClick={() => openDeleteDialogHandler(row.uid)}>
          {t("Delete")}
        </Link>
      ),
    },
  ];

  const isInvalidUser =
    firstName === "" ||
    lastName === "" ||
    email === "" ||
    password === "" ||
    emailTaken === true;

  const isInvalidEditUser = firstName === "" || lastName === "";

  useEffect(() => {
    setLoadingUsers(true);

    const listener = firebase
      .firestore()
      .collection("users")
      .where("role", "==", "admin")
      .onSnapshot(function (querySnapshot) {
        const userObjects = [];
        querySnapshot.forEach(function (doc) {
          const userObject = doc.data();
          userObject.uid = doc.id;

          userObjects.push(userObject);
        });

        setUsers(userObjects);
        setLoadingUsers(false);
      });

    return function cleanup() {
      listener();
    };
  }, [clientId]);

  const submitUserForm = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await firebase
          .firestore()
          .collection("users")
          .where("email", "==", email)
          .get()
          .then(async function (querySnapshot) {
            if (querySnapshot.size) {
              setEmailTaken(true);
              setEmailError(t("This email has been taken"));
            } else {
              await firebase.firestore().collection("newAdminUsers").add({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                password: password,
                role: "admin",
              });

              closeCreateUserDialogHandler();
            }
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      } catch (error) {
        setError(error);
      }
    },
    [firstName, lastName, email, password, phoneNumber, t]
  );

  const submitEditUserForm = useCallback(
    (event) => {
      event.preventDefault();
      try {
        firebase.firestore().collection("users").doc(userToEdit).update({
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
        });

        closeEditUserDialogHandler();
      } catch (error) {
        setError(error);
      }
    },
    [firstName, lastName, phoneNumber, userToEdit]
  );

  function deleteUser(userId) {
    firebase.firestore().collection("users").doc(userId).delete();
    setUserToDelete(null);
    setDialogOpen(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openDeleteDialogHandler = (userId) => {
    setUserToDelete(userId);
    setDialogOpen(true);
  };

  const closeDeleteDialogHandler = () => {
    setUserToDelete(null);
    setDialogOpen(false);
  };

  const openCreateUserDialogHandler = () => {
    setCreateUserDialogOpen(true);
  };

  const closeCreateUserDialogHandler = () => {
    setCreateUserDialogOpen(false);
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setEmail("");
    setPassword("");
  };

  const openEditUserDialogHandler = (userId) => {
    setLoadingUser(true);
    setUserToEdit(userId);
    setEditUserDialogOpen(true);

    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const data = doc.data();

          setLoadingUser(false);
          setEmail(data.email);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setPhoneNumber(data.phoneNumber);
        }
      });
  };

  const closeEditUserDialogHandler = () => {
    setEditUserDialogOpen(false);
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setEmail("");
    setPassword("");
    setUserToEdit(null);
  };

  if (loadingUsers) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container component="main">
      <Header title={t("Admin Users")}>
        <Button onClick={openCreateUserDialogHandler}>{t("New User")}</Button>
      </Header>
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.uid}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={closeDeleteDialogHandler}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Delete User")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Are you sure that you want to delete this user?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialogHandler} color="primary">
            Cancel
          </Button>
          <Button onClick={() => deleteUser(userToDelete)} color="secondary">
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={createUserDialogOpen}
        onClose={closeCreateUserDialogHandler}
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="form-dialog-title">Create User</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={emailTaken === true}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                  setEmailTaken(false);
                }}
                value={email}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                id="phoneNumber"
                type="tel"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
            </Grid>
            <Grid item xs={12}>
              {error && <Alert severity="error">{error.message}</Alert>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateUserDialogHandler} color="primary">
            Cancel
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={submitUserForm}
            disabled={isInvalidUser}
          >
            {t("Submit")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editUserDialogOpen}
        onClose={closeEditUserDialogHandler}
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={loadingUser}
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={loadingUser}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={loadingUser}
                variant="outlined"
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                id="phoneNumber"
                type="tel"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
            </Grid>
            <Grid item xs={12}>
              {error && <Alert severity="error">{error.message}</Alert>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditUserDialogHandler} color="primary">
            Cancel
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={submitEditUserForm}
            disabled={isInvalidEditUser}
          >
            {t("Submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default withRouter(AdminUserList);
