export const SIGN_IN = "/signin";
export const SIGN_UP = "/signup";
export const DASHBOARD = "/";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const CLIENTS = "/clients";
export const CLIENT_DETAILS = "/clients/details";
export const ADD_CLIENT = "/clients/add";
export const EDIT_CLIENT = "/clients/edit";
export const DELETE_CLIENT = "/clients/delete";
export const USERS = "/users";
