import React, { useState, useCallback, useRef } from "react";
import { withRouter } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import firebase from "../../services/firebase";
import { useTranslation } from "react-i18next";

import ClientForm from "./form";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  fileInput: {
    display: "none",
  },
  uploadField: {
    flexGrow: 1,
  },
  uploadContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(3, 0, 2),
  },
}));

function AddClientPage(props) {
  const classes = useStyles();

  const [client, setClient] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    type: "",
    passportFiles: [],
    proofOfResidenceFiles: [],
    professionalCertificationFiles: [],
    proofFiles: [],
    legalRepresentativeFiles: [],
    contactPersonFiles: [],
    error: "",
  });

  const history = props.history;

  let operations = useRef(0);

  const { t } = useTranslation();

  const submitForm = useCallback(
    async (formData) => {
      const fileMap = {};

      const checkForRedirect = () => {
        if (operations.current === 0) {
          history.push(ROUTES.CLIENTS);
        }
      };

      const updateProgress = (snapShot) => {
        let fileData = fileMap[snapShot.ref.fullPath];
        let progress = (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
        formData[fileData.uploadName][fileData.key].progress = progress;

        if (progress === 100) {
          operations.current = operations.current - 1;
        }

        setClient({ ...formData });
      };

      const onError = (err) => {
        formData.error = err.message;

        setClient({ ...formData });
      };

      try {
        const client = await firebase.firestore().collection("clients").add({
          name: formData.name,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          type: formData.type,
          deleted: 0,
        });

        let totalUploads = 0;

        [
          "passportFiles",
          "proofOfResidenceFiles",
          "professionalCertificationFiles",
          "proofFiles",
          "legalRepresentativeFiles",
          "contactPersonFiles",
        ].map((uploadName) => {
          totalUploads += Object.keys(formData[uploadName]).length;
          return true;
        });

        if (totalUploads === 0) {
          history.push(ROUTES.CLIENTS);
        }

        [
          "passportFiles",
          "proofOfResidenceFiles",
          "professionalCertificationFiles",
          "proofFiles",
          "legalRepresentativeFiles",
          "contactPersonFiles",
        ].map((uploadName) => {
          let keys = Object.keys(formData[uploadName]);
          if (keys.length > 0) {
            let folderName = uploadName
              .replace(/([a-zA-Z])(?=[A-Z])/g, "$1-")
              .toLowerCase();

            keys.map((key) => {
              operations.current = operations.current + 1;

              let ref = `uploads/clients/${client.id}/${folderName}/${formData[uploadName][key].file.name}`;

              fileMap[ref] = {
                key,
                uploadName,
              };

              firebase
                .storage()
                .ref(ref)
                .put(formData[uploadName][key].file)
                .on("state_changed", updateProgress, onError, checkForRedirect);

              return true;
            });
          }

          return true;
        });
      } catch (error) {
        formData.error = error.message;

        setClient({ ...formData });
      }
    },
    [history]
  );

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {t("New Client")}
        </Typography>
      </div>
      <ClientForm
        client={client}
        submitLabel={t("Save")}
        backUrl={ROUTES.CLIENTS}
        onSubmit={submitForm}
      />
    </Container>
  );
}

export default withRouter(AddClientPage);
